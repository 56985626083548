<template>
  <div>
    <b-card title="Clients">
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver ref="simpleRules">
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mt-1"
              >
                <b-form-group>
                  <ValidationProvider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <v-select
                      v-model="client_selected"
                      :options="clients"
                      :reduce="option => option.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select Client"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                class="mt-md-1"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="{ name: 'client-dashboard', params: { id: client_selected } }"
                >
                  <feather-icon
                    icon="CornerLeftDownIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Get Data</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
    vSelect,
    BForm,
  },
  data() {
    return {
      required,
      loading: false,
      clients: [
        {
          id: '1',
          name: 'Jhatta',
        },
        {
          id: '2',
          name: 'MNG',
        },
        {
          id: '3',
          name: 'BlueWagon',
        },
      ],
      client_selected: null,
    }
  },
}
</script>

<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
